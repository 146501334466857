
import { defineComponent, ref } from '@cloudfun/core'
import UploadAdapterPlugin from '@/global-components/ckeditor/upload-adapter-plugin'
import ClassicEditor from '@/global-components/ckeditor/classic-editor'

export default defineComponent({
  setup() {
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      removePlugins: ["Markdown"],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          // 'htmlEmbed',
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    return {
      type: ref(0),
      term: ref<any>({ Content: {} }),
      classicEditor,
      editorConfig,
    };
  },
  async created() {
    await this.onTypeChanged();
  },
  methods: {
    async onTypeChanged() {
      await this.$model.dispatch("term/read", this.type).then(
        (term) => {
          this.term = term;
        },
        (failure) => {
          this.$send("error", {
            subject: "讀取失敗",
            content: failure.message,
          });
        }
      );
    },
    async save() {
      await this.$model.dispatch("term/save", this.term).then(
        (term) => {
          this.term.Number = term.Number;
          this.$send("info", {
            subject: "更新成功",
            content: "條款內容已更新",
          });
        },
        (failure) => {
          this.$send("error", {
            subject: "更新失敗",
            content: failure.message,
          });
        }
      );
    },
  },
});
