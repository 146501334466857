<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">條款管理</h2>
      <span class="rounded px-2 py-1 text-blue-600 bg-blue-200">
        類型
        <vxe-select
          v-model="type"
          :options="Object.values($model.enums.TermType).map(e => { return { label: e.Name, value: e.Value } })"
          @change="onTypeChanged"
        />
      </span>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box pl-5 pr-2 pt-5 pb-5 mt-5 mb-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <vxe-form title-width="80">
          <vxe-form-item span="12" title="編號">
            <vxe-input v-model="term.Number" placeholder="系統自動產生" readonly></vxe-input>
          </vxe-form-item>
          <vxe-form-item span="12" title="名稱">
            <vxe-input v-model="term.Name" placeholder="請輸入文字"></vxe-input>
          </vxe-form-item>
          <vxe-form-item span="24">
            <CKEditor
              v-model="term.Content.Content"
              :editor="classicEditor"
              :config="editorConfig"
            />
          </vxe-form-item>
        </vxe-form>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="text-right">
        <button
          type="button"
          class="button w-24 bg-theme-1 text-white"
          @click="save"
        >
          更新
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@cloudfun/core'
import UploadAdapterPlugin from '@/global-components/ckeditor/upload-adapter-plugin'
import ClassicEditor from '@/global-components/ckeditor/classic-editor'

export default defineComponent({
  setup() {
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      removePlugins: ["Markdown"],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          // 'htmlEmbed',
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    return {
      type: ref(0),
      term: ref<any>({ Content: {} }),
      classicEditor,
      editorConfig,
    };
  },
  async created() {
    await this.onTypeChanged();
  },
  methods: {
    async onTypeChanged() {
      await this.$model.dispatch("term/read", this.type).then(
        (term) => {
          this.term = term;
        },
        (failure) => {
          this.$send("error", {
            subject: "讀取失敗",
            content: failure.message,
          });
        }
      );
    },
    async save() {
      await this.$model.dispatch("term/save", this.term).then(
        (term) => {
          this.term.Number = term.Number;
          this.$send("info", {
            subject: "更新成功",
            content: "條款內容已更新",
          });
        },
        (failure) => {
          this.$send("error", {
            subject: "更新失敗",
            content: failure.message,
          });
        }
      );
    },
  },
});
</script>
